<template>
  <v-container fluid>
    <CRUD
      ref="crud"
      id-method="params"
      :form-size="formSize"
      :fields="fields"
      :table="table"
      :api="api"
      @changed:mode="getFormData"
      @form:load="onFormLoad"
    >
      <!-- Concatenate Adobe and TM Links -->
      <template v-slot:[`table.col.Links`]="{ item }">
        <span v-html="item.Links['ADOBE_CAMPAIGN_ID']"></span>
        <span
          v-if="
            item.Links['ADOBE_CAMPAIGN_ID'] &&
              item.Links['ADOBE_CAMPAIGN_ID'].length > 0 &&
              item.Links['TM_CAMPAIGN_ID'] &&
              item.Links['TM_CAMPAIGN_ID'].length > 0
          "
        >
          |
        </span>
        <span v-html="item.Links['TM_CAMPAIGN_ID']"></span>
      </template>

      <!------------------------------
        LineOrders Edit Form
      ------------------------------->
      <template v-slot:form="{ fields, id, formSize, headerText, close }">
        <v-toolbar short flat>
          <v-btn icon @click="close">
            <v-icon>{{ formSize == "full" ? "mdi-arrow-left" : "mdi-close" }}</v-icon>
          </v-btn>
          <v-toolbar-title>{{ headerText }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <!------------------------------
                    LO Button tabs
          ------------------------------->
          <v-btn-toggle v-model="tab" borderless class="mt-4" v-if="formLoaded">
            <v-container class=" pb-0 pt-0 pl-0 pr-0">
              <v-btn
                :disabled="!formLoaded"
                value="general"
                class="text-none btn-tabs"
                :class="{ 'v-item--active v-btn--active': tab == 'general' }"
              >
                <v-container>
                  <v-row class="mt-0 mb-0 ml-0 mr-0">
                    <v-icon large :color="primaryBlue">mdi-clipboard-account-outline</v-icon>
                  </v-row>
                  <v-row class="mt-0 mb-0 ml-0 mr-0">
                    <span class="hidden-sm-and-down">General</span>
                  </v-row>
                </v-container>
              </v-btn>
            </v-container>

            <v-container class=" pb-0 pt-0 pl-0 pr-0">
              <v-btn
                :disabled="!formLoaded"
                value="options"
                class="text-none btn-tabs"
                :class="{ 'v-item--active v-btn--active': tab == 'options' }"
              >
                <v-container>
                  <v-row class="mt-0 mb-0 ml-0 mr-0">
                    <v-icon large :color="primaryBlue">mdi-cog</v-icon>
                  </v-row>
                  <v-row class="mt-0 mb-0 ml-0 mr-0">
                    <span class="hidden-sm-and-down">Options</span>
                  </v-row>
                </v-container>
              </v-btn>
            </v-container>

            <v-container class=" pb-0 pt-0 pl-0 pr-0" v-if="!newLON">
              <v-btn
                :disabled="!formLoaded"
                value="documents"
                class="text-none btn-tabs"
                :class="{ 'v-item--active v-btn--active': tab == 'documents' }"
              >
                <v-container>
                  <v-row class="mt-0 mb-0 ml-0 mr-0">
                    <v-icon large :color="primaryBlue">mdi-file-document-multiple-outline</v-icon>
                  </v-row>
                  <v-row class="mt-0 mb-0 ml-0 mr-0">
                    <span class="hidden-sm-and-down">Documents</span>
                  </v-row>
                </v-container>
              </v-btn>
            </v-container>

            <v-container class=" pb-0 pt-0 pl-0 pr-0" v-if="!newLON">
              <v-btn
                :disabled="!formLoaded"
                @click="createCampaign('Adobe')"
                value="adobe"
                class="remove-active text-none btn-tabs"
                :class="{ 'remove-active': tab == 'adobe' }"
              >
                <v-container>
                  <v-row class="mt-0 mb-0 ml-0 mr-0">
                    <img
                      height="36px"
                      width="36px"
                      src="/adobe-logo.svg"
                      :class="formLoaded ? 'adobe-logo' : 'adobe-logo-disabled'"
                    />
                  </v-row>
                  <v-row class="mt-0 mb-0 ml-0 mr-0">
                    <span class="hidden-sm-and-down">Create<br />Adobe Campaign</span>
                  </v-row>
                </v-container>
              </v-btn>
            </v-container>

            <v-container class=" pb-0 pt-0 pl-0 pr-0" v-if="!newLON">
              <v-btn
                :disabled="!formLoaded"
                @click="createCampaign('TM')"
                value="tm"
                class="remove-active text-none btn-tabs"
                :class="{ 'remove-active': tab == 'tm' }"
              >
                <v-container>
                  <v-row class="mt-0 mb-0 ml-0 mr-0">
                    <v-icon large :color="primaryBlue">mdi-card-account-phone-outline</v-icon>
                  </v-row>
                  <v-row class="mt-0 mb-0 ml-0 mr-0">
                    <span class="hidden-sm-and-down">Create<br />TM Campaign</span>
                  </v-row>
                </v-container>
              </v-btn>
            </v-container>
          </v-btn-toggle>
          <v-spacer></v-spacer>

          <v-menu offset-y v-if="!newLON && !apiError && formLoaded" :disabled="!formLoaded">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" text>
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                link
                :href="
                  $store.getters['auth/getApiHost']['tr_host'] + '/resource-library/lo/' + id + '/'
                "
                rel="noopener noreferrer"
                target="_blank"
              >
                <v-list-item-title>TechRepublic Listing Page</v-list-item-title>
              </v-list-item>
              <v-list-item :disabled="modifiedFields.length > 0" @click="clone()">
                <v-list-item-title>Clone</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn v-if="formLoaded" class="ml-3" color="primary" @click="save()" :disabled="apiError || !formLoaded || !valid">
            Save
          </v-btn>
        </v-toolbar>

        <v-card-text class="pt-2">
          <div v-if="formLoaded" v-show="tab != 'documents' && !apiError">
            <b>LINEORDER:</b> {{ id != "+" ? id : "N/A" }}
            <span v-if="LO.IONumber"><i> (Old IO Number: {{LO.IONumber}})</i></span>
          </div>

          <CRUDApiError v-if="!newLON && LO && LO.ApiError" :ApiError="LO.ApiError" :page="'edit'"/>

          <v-form class="pt-4" v-if="newLON || (LO && !LO.ApiError)" v-model="valid">
            <v-container fluid class="pt-0">
              <v-row justify="center" class="mt-0 mb-0 ml-0 mr-0">
                <!------------------------------
                    Form Inputs
                ------------------------------->
                <template v-for="(item, index) in fields">
                  <!--------------
                    General Tab
                  --------------->
                  <v-col
                    v-show="tab == 'general'"
                    v-if="!item.hidden"
                    :key="index"
                    :cols="item.cols"
                  >
                    <div
                      v-if="
                        item.field == 'CoordinatorId' &&
                          $store.state.auth.user.country != 'US/Pacific'
                      "
                    >
                      <!--Verify the value for CoordinatorId is still passed when save becomes available-->
                      <input type="hidden" :name="item.field" value="item.value" />
                    </div>

                    <!-- Add onChange Method to Start & End Date -->
                    <div v-if="item.field == 'StartDate' || item.field == 'EndDate'">
                      <InputControl
                        :attrId="item.field"
                        :label="item.label"
                        :ref="`input_${item.field}`"
                        :value="item.value"
                        :attrType="item.type"
                        :attrRef="item.lov"
                        :multiValue="item.multiple"
                        :control="item.control"
                        :disabled="item.disabled"
                        :rules="item.validation"
                        :bind="item.bind"
                        @update:value="onUpdateValue($event, item)"
                      />
                    </div>

                    <template v-else-if="item.field == 'OrderNumber' || item.field == 'LineOrder'">
                      <v-text-field
                        dense
                        :attrId="item.field"
                        :label="item.label + '*'"
                        :ref="`input_${item.field}`"
                        :value="item.value"
                        :attrType="item.type"
                        :attrRef="item.lov"
                        :multiValue="item.multiple"
                        :control="item.control"
                        :disabled="item.disabled"
                        :rules="[(v) => !!v || 'Required']"
                        :bind="item.bind"
                        @blur="isNumericValidation($event,item)"
                        @input="onUpdateValue($event, item)"
                      >
                      </v-text-field>
                    </template>

                    <template v-else-if="item.field == 'Slug' || item.field == 'LineOrderDesc'">
                      <v-text-field
                        dense
                        :attrId="item.field"
                        :label="item.label + '*'"
                        :ref="`input_${item.field}`"
                        :value="item.value"
                        :attrType="item.type"
                        :attrRef="item.lov"
                        :multiValue="item.multiple"
                        :control="item.control"
                        :disabled="item.disabled || (item.field == 'Slug' ? loadingSlug : false)"
                        :rules="[(v) => !!v || 'Required']"
                        :bind="item.bind"
                        @blur="getSlug($event, item)"
                        :loading="item.field == 'Slug' ? loadingSlug : false"
                        @input="onUpdateValue($event, item)"
                        @change="item.field == 'Slug' ? onUpdateValue($event, item) : ''"
                      >
                      </v-text-field>
                    </template>

                    <InputControl
                      v-else-if="!item.hidden"
                      :attrId="item.field"
                      :label="item.label"
                      :ref="`input_${item.field}`"
                      :value="item.value"
                      :attrType="item.type"
                      :attrRef="item.lov"
                      :multiValue="item.multiple"
                      :control="item.control"
                      :disabled="item.disabled"
                      :rules="item.validation"
                      :bind="item.bind"
                      @update:value="onUpdateValue($event, item)"
                    />
                  </v-col>

                  <!--------------
                    Hiddenn Fields
                    Added V-show until items not shown can be accessed when hidden by crudForm
                  --------------->
                  <InputControl
                    v-else
                    :key="index"
                    v-show="!item.hidden"
                    :attrId="item.field"
                    :label="item.label"
                    :ref="`input_${item.field}`"
                    :value="item.value"
                    :attrType="item.type"
                    :attrRef="item.lov"
                    :multiValue="item.multiple"
                    :control="item.control"
                    :disabled="item.disabled"
                    :rules="item.validation"
                    :bind="item.bind"
                    @update:value="onUpdateValue($event, item)"
                  />

                </template>
                <!------------------------------
                    End Of Form Inputs Loop
                ------------------------------->
                <v-row
                    class="mt-0 mb-0 ml-0 mr-0 general-btn-group"
                    align="center"
                    justify="center"
                    v-show="tab == 'general'"
                    v-if="!newLON"
                    style="width:100%"
                  >
                    <v-btn
                      color="indigo"
                      outlined
                      :disabled="!formLoaded"
                      value="general"
                      class="ma-2 text-none"
                    >
                      <v-container>
                        <v-row class="mt-0 mb-0 ml-0 mr-0">
                          <v-icon large :color="primaryBlue">mdi-cancel</v-icon>
                        </v-row>
                        <v-row class="mt-0 mb-0 ml-0 mr-0">
                          <span class="hidden-sm-and-down"
                            >Engagement Restrictions<br />{{
                              LO && LO.CustomLeads != 0 ? "Enabled" : "Disabled"
                            }}</span
                          >
                        </v-row>
                      </v-container>
                    </v-btn>

                    <app-limit-delivery v-if="formLoaded" :formLoaded="formLoaded" :LO="LO" :primaryBlue="primaryBlue"></app-limit-delivery>
                    <app-custom-questions v-if="formLoaded" :formLoaded="formLoaded" :LO="LO" :primaryBlue="primaryBlue"></app-custom-questions>

                  </v-row>
              </v-row>
              <!--------------------------------------------------
                  On Date Change, Show BulkUpdate Prompt
              --------------------------------------------------->
              <v-row justify="center" class="mt-0 mb-0 ml-0 mr-0">
                <v-dialog v-model="bulkUpdateDialog" max-width="450">
                  <v-card>
                    <v-card-title class="headline">Bulk Update</v-card-title>
                    <v-card-text>Update all {{ bulkUpdateType }} dates within this Line Order</v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="green darken-1" text @click="setbulkUpdate(false)">No </v-btn>
                      <v-btn color="green darken-1" text @click="setbulkUpdate(true)">Yes </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
              <!--------------
                  Options Tab
              --------------->
              <div v-show="tab == 'options' && formLoaded">
                <app-options :formLoaded="formLoaded" :id="id" :LO="LO" :newLON="newLON" :onUpdateValue="onUpdateValue" :origLO="origLO" :topics="topics" :updateValue="$refs.crud ? $refs.crud.updateValue : '' "></app-options>
              </div>
              <!--------------
                  Docs Tab
              --------------->
              <div v-show="tab == 'documents' && formLoaded">
                <app-documents :allDocs="allDocs" :getAllDocs="getAllDocs" :id="id" :LO="LO" :origLO="origLO"></app-documents>
              </div>
              <!--------------------------------------------------
                  LON Edit Info
              --------------------------------------------------->
              <v-row
                justify="center"
                class="mt-0 mb-0 ml-0 mr-0 edit-log"
                v-if="!newLON && (tab == 'general' || tab == 'options')"
              >
                <v-col cols="2" align-self="center" class="d-flex justify-center">
                  <a href="#">View Edit Log</a>
                </v-col>
                <v-col cols="4" align-self="center">
                  <v-text-field name="EditReason" label="Reason For Edit"></v-text-field>
                </v-col>
                <v-col cols="3">
                  <p class="mb-0">
                    <b>Created On: </b>
                    <span v-if="formLoaded">{{
                      LO.CreatedDate ? LO.CreatedDate.split(" ")[0].replace(/-/g, "/") : ""
                    }}</span>
                  </p>
                  <p class="mb-0">
                    <b>Created By:</b> <span v-if="formLoaded">{{ LO.CreatedBy }}</span>
                  </p>
                  <p class="mb-0">
                    <b>Country Created In:</b> <span v-if="formLoaded">{{ LO.LineOrderCountry }}</span>
                  </p>
                </v-col>

                <v-col cols="3">
                  <p class="mb-0">
                    <b>Last Modified On: </b>
                    <span v-if="formLoaded">{{
                      LO.LastModifiedDate ? LO.LastModifiedDate.replace(/\./g, "/") : ""
                    }}</span>
                  </p>
                  <p class="mb-0">
                    <b>Last Modified By:</b> <span v-if="formLoaded">{{ LO.LastModifiedBy }}</span>
                  </p>
                  <p class="mbb-0">
                    <b>Active Docs: </b>
                    <span v-if="formLoaded">{{ activeDocs ? activeDocs.length : 0 }}</span>
                  </p>
                </v-col>
              </v-row>
              <v-row v-if="!newLON && (tab == 'general' || tab == 'options')" justify="start">
                <v-col :cols="leadStatus.Leads && leadStatus.Leads.length == 0 ? 2 : 1"><b>Leads Status:</b> <span v-if="leadStatus.Leads.length == 0">No Leads</span></v-col>
                <v-col v-show="leadStatus.Leads && leadStatus.Leads.length > 0" v-for="(lead, index) in leadStatus.Leads" :key="index"> {{lead.LeadStatus + " - " + lead.Total}}</v-col>
              </v-row>

            </v-container>
            <!--------------
                Additional Hiddenn Inputs
            --------------->
            <input type="hidden" name="DEBUG" :value="$route.query.DEBUG">
            <input v-if="newLON" type='hidden' name='loDist' value='usOrder' />
            <input v-if="newLON" type="hidden" name="ionumber" id="ionum" maxlength="20" value="">

          </v-form>
        </v-card-text>
        <!------------------------------------------
          End of LineOrders Edit Form
        ------------------------------------------>
      </template>
    </CRUD>
  </v-container>
</template>

<script>
import CRUD from "@/components/util/CRUD/CRUD";
import CRUDApiError from "@/components/util/CRUD/CRUDApiError";
import CustomQuestions from "@/components/directory/lineorder/CustomQuestions";
import Documents from "@/components/directory/lineorder/Documents";
import InputControl from "@/components/util/InputControl";
import LimitDelivery from "@/components/directory/lineorder/LimitDelivery";
import Options from "@/components/directory/lineorder/Options";

export default {
  components: {
    appCustomQuestions: CustomQuestions,
    appDocuments: Documents,
    appOptions: Options,
    appLimitDelivery: LimitDelivery,
    CRUD,
    CRUDApiError,
    InputControl
  },
  data() {
    return {
      allDocs: [],
      bulkUpdateDialog: false,
      bulkUpdateType: "",
      formLoaded: false,
      formSize: "full", // full or width number, default full
      origLO: {},
      leadStatus: null,
      LO: null,
      loadingSlug: false,
      modifiedFields: [],
      primaryBlue: "#005eb6",
      tab: "general",
      topics: null,
      topicsFetched: false,
      updateStartDates: false,
      updateEndDates: false,
      values: {},
      valid: false,
      api: {
        url: "/directory/lineorder/table/",
        params: {
          tableName: "itpapers.LineOrder",
          user: this.$store.state.auth.user.username
        }
      },
      table: {
        text: "LineOrders", // table name to display in crud component
        key: "LineOrderNumber", // primary key of table for various usage
        default: {
          //* used for default table option
          page: 1,
          itemsPerPage: 10,
          sortBy: ["ModifiedDate"],
          sortDesc: [true]
        },
        components: {
          //* for hiding component that are not needed
          filter: true, //d[true]
          delete: false,
          add: true,
          quickFilter: [
            {
              name: "FeaturedDocId",
              text: "Featured DocId",
              type: "text",
              width: "250px"
            },
            {
              name: "IONumber",
              text: "Operative LineOrder#",
              type: "text",
              width: "250px"
            },
            {
              name: "LineOrderCountry",
              text: "Created In",
              type: "select",
              multiple: true
            },
            {
              name: "Status",
              type: "select",
              value: "Active"
            }
          ]
        }
        //partialUpdate: true //default true
      },
      fields: [
        {
          text: "Internal LineOrder #",
          name: "LineOrderNumber",
          searchable: true,
          editable: false,
          input: {
            hidden: true
          }
        },
        {
          text: "Operative Order Id",
          name: "OrderNumber",
          searchable: true,
          input: {
            order: 2,
            cols: 4,
            validation: ["mandatory"]
          }
        },
        {
          text: "Operative Line ID",
          name: "LineOrder",
          searchable: true,
          input: {
            order: 3,
            cols: 4,
            validation: ["mandatory"]
          }
        },
        {
          text: "Start Date",
          name: "StartDate",
          searchable: true,
          input: {
            type: "D",
            order: 7,
            cols: 4,
            validation: ["mandatory"],
            value: this.getDate()
          }
        },
        {
          text: "End Date",
          name: "EndDate",
          searchable: true,
          input: {
            type: "D",
            order: 10,
            cols: 4,
            validation: ["mandatory"],
            value: this.getDate(6)
          }
        },
        {
          text: "Status",
          name: "Status",
          editable: false,
          input: {
            //if: { update: "hidden" },
            lov: ["Active", "Expired", "All"],
            notranslate: true
          }
        },
        {
          text: "Collect Leads",
          name: "CollectLeads",
          searchable: true,
          editable: false,
        },
        {
          text: "Lead/Click Goal",
          name: "NumberOfLeads",
          searchable: true,
          input: {
            order: 5,
            cols: 4,
            validation: ["mandatory"]
          }
        },
        {
          text: "CPL/CPC",
          name: "PricePerLead",
          input: {
            order: 6,
            cols: 4,
            validation: ["mandatory"]
          }
        },
        {
          text: "Program Type",
          name: "ProgramType",
          searchable: true,
          input: {
            type: "L",
            lov: ["Lead Capture/Collect Leads", "Direct Response/Bypass (no reg required)"],
            notranslate: true,
            order: 12,
            cols: 4,
            validation: ["mandatory"],
            value: "Direct Response/Bypass (no reg required)"
          }
        },
        {
          text: "Campaign Name",
          name: "LineOrderDesc",
          searchable: true,
          input: {
            order: 1,
            cols: 4,
            validation: ["mandatory"]
          }
        },
        {
          text: "Campaign Filters",
          name: "BillingDesc",
          searchable: true,
          editable: false
        },
        {
          text: "Last Modified On",
          name: "LastModifiedDate",
          searchable: true,
          input: {
            hidden: true,
            cols: 4
          }
        },
        {
          text: "Created On",
          name: "CreatedDate",
          searchable: false,
          listable: false,
          input: {
            hidden: true,
            cols: 4
          }
        },
        {
          text: "Created By",
          name: "CreatedBy",
          listable: false,
          searchable: true,
          input: {
            hidden: true,
            cols: 4
          }
        },
        {
          text: "Last Modified By",
          name: "LastModifiedBy",
          searchable: false,
          listable: false,
          input: {
            hidden: true
          }
        },
        {
          text: "Document Topics",
          name: "DocumentTopics",
          editable: false
        },
        {
          text: "Leads Captured",
          name: "LeadStatus",
          editable: false,
        },
        {
          text: "Links",
          name: "Links",
          editable: false,
        },
        {
          text: "Device Type",
          name: "",
          searchable: true,
          editable: false
        },
        {
          text: "Country",
          name: "LineOrderCountry",
          listable: false,
          input: {
            if: { update: "disabled" },
            type: "L",
            lov: ["AU", "CN", "FR", "SG", "UK", "US"],
            order: 9,
            cols: 4,
            validation: ["mandatory"]
          }
        },
        {
          text: "Slug",
          name: "Slug",
          listable: false,
          searchable: true,
          input: {
            order: 4,
            cols: 4
          }
        },
        {
          text: "Auto Phone Tag",
          name: "AutoPhoneTag",
          listable: false,
          searchable: true,
          editable: false,
        },
        {
          text: "Track Activity",
          name: "TrackActivity",
          listable: false,
          searchable: true,
          editable: false,
        },
        {
          text: "Sales Rep.",
          name: "ExecutiveId",
          listable: false,
          searchable: false,
          input: {
            type: "L",
            order: 8,
            cols: 4,
            validation: ["mandatory"]
          },
          foreign: {
            table: "itpapers.AccountExecutive",
            key: "AccountExecutiveId",
            display: "EmailAddress",
            where: "Active = 1"
          }
        },
        {
          text: "Account Coordinator",
          name: "CoordinatorId",
          listable: false,
          searchable: false,
          input: {
            type: "L",
            order: 11,
            cols: 4,
            validation: ["mandatory"],
            value: 1624
          },
          foreign: {
            table: "itpapers.AccountExecutive",
            key: "AccountExecutiveId",
            display: "EmailAddress",
            where: "Active = 1"
          }
        },
        //        {
        //          text: "Bulk Update",
        //          name: "BulkUpdate",
        //          listable: false,
        //          searchable: false,
        //          input: {
        //            if: {add: "hidden"},
        //            type: "L",
        //            lov: [{text: "Update all START dates within this Line Order", value: "updateStartDate"}, {text: "Update all END dates within this Line Order", value: "updateEndDate"}],
        //            control: "checkbox",
        //            notranslate: true,
        //            order: 13,
        //            cols: 4,
        //            validation: ["mandatory"]
        //          }
        //        },
        {
          text: "Campaign Filters Summary",
          name: "CampaignFiltersSummary",
          listable: false,
          searchable: false,
          input: {
            control: "textarea",
            cols: 4,
            order: 14
          }
        },
        {
          text: "Campaign Notes",
          name: "CampaignNotes",
          listable: false,
          searchable: false,
          input: {
            control: "textarea",
            cols: 4,
            order: 15
          }
        },
      ]
    };
  },
  computed: {
    activeDocs() {
      if (this.allDocs?.length > 0)
        return this.allDocs.filter((doc) => doc.CurrentStatus == "Active");
      else return [];
    },

    apiError() {
      return !this.newLON && this.LO?.ApiError ? true : false;
    },

    directoryEnvUrl() {
      return this.$store.getters["auth/getApiHost"]["ups_admin"];
    },

    newLON() {
      return this.$route.params.id == "+" ? true : false;
    },
  },

  methods: {
    //if only used for Promote In medusa and Display in DFP, this function call is no longer needed
    checkNoRedirect() {
      alert("check no redirect");
    },

    clone() {
      alert("cloning LON");
    },

    createCampaign(type) {
      alert(`Create ${type} Campaign`);
    },

    enablescoring() {},

    getDate(months = null, days = null, years = null) {
      let today = new Date();

      days ? today.setDate(today.getDate() + Number(days)) : "";
      months ? today.setMonth(today.getMonth() + Number(months)) : "";
      years ? today.setFullYear(today.getFullYear() + Number(years)) : "";

      let dd = today.getDate();
      let mm = today.getMonth() + 1; //As January is 0.
      let yyyy = today.getFullYear();

      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;

      return yyyy + "-" + mm + "-" + dd;
    },

    getAccountExecutiveId() {
      this.$axios
        .get("/directory/lineorder/getAccountExecutiveId", {
          params: { email: this.$store.state.auth.user.email }
        })
        .then((res) => {
          if (res.data["AccountExecutiveId"])
            this.$refs.crud.updateValue("CoordinatorId", res.data["AccountExecutiveId"]);
        });
    },

    getAllDocs() {
      this.$axios
        .get("/directory/lineorder/getAllDocs", { params: { LON: this.$route.params.id } })
        .then((res) => {
          this.allDocs = res.data;
        });
    },

    getFormData(e) {
      this.tab = "general";
      this.formLoaded = false;
      if (e == "edit") {
        this.getTopics();
        this.getAllDocs();
        this.getLeadStatus();
      }
    },

    getLeadStatus() {
      this.$axios
        .get("/directory/lineorder/getLeadStatus", { params: { LON: this.$route.params.id } })
        .then((res) => {
          this.leadStatus = res.data;
        });
    },

    getSlug($event, item) {
      let campaignName = $event.target.value;

      if(campaignName != ""){
        if ((item.field == "Slug" && item.value == "") || item.field == "LineOrderDesc") {
          this.loadingSlug = true;
          this.$axios
            .get("/directory/lineorder/generateSlug", {
              params: {
                url: campaignName,
                id: this.$route.params.id == "+" ? 0 : this.$route.params.id
              }
            })
            .then((res) => {
              this.$refs.crud.updateValue("Slug", res.data);
              this.loadingSlug = false;
            });
        }
      }
    },

    getTopics() {
      if (!this.topicsFetched) {
        this.$axios.get("/directory/lineorder/getTopics").then((res) => {
          if (res.data) {
            this.topics = res.data;
            this.topics.unshift({ TopicID: 0, TopicName: "Choose A Topic" });
          }
        });
      }
      this.topicsFetched = true;
    },

    isNumericValidation($event,item){
      let value = this.newLON ? $event.target.value : item.value;
      if(isNaN(value)){
        alert(item.label + " Must Be Numeric");
        //Remove all non numeric values from Operative Line ID || Operative Order ID
        let numericItem = value.replace(/\D/g,'');
        this.$refs.crud.updateValue(item.field, numericItem);
      }
    },

    onFormLoad(e) {
      this.LO = e;

      if(!this.apiError){

        if(!this.newLON){
          this.LO.oldline = JSON.parse(JSON.stringify(this.LO.LineOrderNumber));
          //Convert Values to Number for v-switch
          this.LO.enableScoring = this.LO?.ScoringTopic != 0 ? 1 : 0;
          this.LO.EnableDailyCap = Number(this.LO.EnableDailyCap);
          this.LO.enableDFP = Number(this.LO.enableDFP);
          if(this.LO.noredirect_dfp) this.LO.noredirect_dfp = Number(this.LO.noredirect_dfp);
          if(this.LO.noredirect_medusa) this.LO.noredirect_medusa = Number(this.LO.noredirect_medusa);
          this.LO.TMSync = Number(this.LO.TMSync);
          this.LO.TMSync = this.LO.TMSync == 2 ? 0 : this.LO.TMSync;

          if(this.LO.MedusaLink){
            this.LO.MedusaLink = this.LO.MedusaLink[0];
            this.LO.MedusaPromote = Number(this.LO.MedusaPromote[0]);
          }
        }

        //origLo Used for Documents Tab to check against original unchanged values
        this.origLO = JSON.parse(JSON.stringify(this.LO));
        if(this.LO?.noredirect_dfp) this.LO.noredirect_dfp = parseInt(this.LO.noredirect_dfp);
        if(this.LO?.noredirect_medusa) this.LO.noredirect_medusa = parseInt(this.LO.noredirect_medusa);

        if(this.newLON) this.getAccountExecutiveId();

        //if(!this.newLON && this.LO?.noredirect_medusa != undefined && this.LO?.noredirect_dfp != undefined)
        this.formLoaded = true;
        //else if (this.newLON)
           //this.formLoaded = true;
      }
    },

    onUpdateValue(value, item) {
      if (
        (item.field == "EnableDailyCap" ||
          item.field == "ScoringTopic" ||
          item.field == "enableDFP") &&
        value == null
      ) {
        value = false;
      }
      //item.value = value;

      this.$refs.crud.updateValue(item.field, value);

      if (this.modifiedFields.indexOf(item.field) == -1) {
        this.modifiedFields.push(item.field);
      }

      if(!this.newLON && (item.field == "StartDate" || item.field == "EndDate")){
        this.bulkUpdateType = item.field == "StartDate" ? "start" : "end";

        if (this.activeDocs?.length > 0) this.bulkUpdateDialog = true;
      }
      //this.values[item.field] = value;
      //item.value = value;
      // this.$emit("form:input", {
      //   values: this.values,
      //   modifiedFields: this.modifiedFields,
      //   item: item
      // });

      this.$forceUpdate();
    },

    save() {},

    setbulkUpdate(val) {
      if (this.bulkUpdateType == "start") this.updateStartDates = val;
      else this.updateEndDates = val;

      this.bulkUpdateType = "";
      this.bulkUpdateDialog = false;
    },
  },
  created() {
    //For when LON Edit is accessed directly
    if (this.$route.params?.id != "+" && this.$route.params?.id != undefined) this.getFormData("edit");
  },

  watch: {
    tab: function(newV, oldV) {
      if (newV == "tm" || newV == "adobe" || newV == undefined) {
        this.internalLazyValue = oldV;
        this.tab = oldV;
      }
    }
  }
};
</script>
<style lang="scss">
.v-expansion-panel {
  border: solid 1px rgba(0, 0, 0, 0.2);
}

.btn-tabs,
.general-btn-group {
  .row {
    margin-left: 0;
    margin-right: 0;
    place-content: center !important;
    width: 100% !important;

    &:first-child {
      //margin-bottom: 12px !important;
    }
    span {
      font-weight: 300 !important;
    }
  }

  .adobe-logo-disabled {
    filter: invert(0%) sepia(100%) saturate(4%) hue-rotate(162deg) brightness(100%) contrast(101%);
    opacity: 0.6;
  }

  &.remove-active.v-btn:not(.v-btn--text):not(.v-btn--outlined).v-btn--active:before,
  &.remove-active.theme--light.v-btn--active:hover::before,
  .theme--light.v-btn--active::before {
    opacity: 0;
  }
}
.btn-tabs {
  width: 120px !important;
  height: 60px !important;
  margin-right: 1em;

  span {
    font-size: 12px;
  }
}
.general-btn-group {
  padding-top: 1em;
  padding-bottom: 2em;

  .v-btn {
    height: 70px !important;
    width: 150px !important;

    span {
      font-size: 10px;
    }
  }
}
/////////
.edit-log {
  background-color: #f2f2f2;
  border: solid 1px rgba(0, 0, 0, 0.3);
}
</style>
